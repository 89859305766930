<script>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { signUp, sendCode } from '@/http/api'
import countDown from '@/components/countdown'
import md5 from 'md5'
import router from '@/routes'
import { http } from '@/http'
import { needLoading, userAuth } from '@/reactive'
import { Toast } from 'vant'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    let tel = ref('')
    let password = ref('')
    let code = ref('')
    let checked = ref(false)
    let clickable = ref(false)
    let slogan = ref('')
    let route = useRoute()
    let router = useRouter()

    const onSubmit = (fields) => {
      if(!checked.value) {
        Toast('请勾选同意后再进行注册')
        return false
      }
      if(needLoading.value) {
        return false
      }
      needLoading.value = true
      
      signUp({
        loginType: 'CAPTCHA',
        tel: fields.tel,
        captchaCode: fields.code,
        loginPwd: md5(fields.password),
        regFlag: true,
        referrerRole: route.query.referrerRole,
        referrerId: route.query.referrerId
      }).then(data => {
        if(data.result) {
          Toast.success('商户账号注册成功!')
          router.push('/success-fallback?status=success')
          //window.localStorage.setItem('userAuth', JSON.stringify(data.result))
        }
      }).catch(() => {})
    }


    const handleSendCode = (a) => {
      if(!clickable.value) {
        Toast('请输入手机号')
        return false
      }
      sendCode({
        templateCode: 'SMS_MERCHANT_LOGIN_REGIST',
        smsPhoneNumber: tel.value
      }).then(data => {
        
      })
    }

    watch(() => tel.value, (a, b) => {
      clickable.value = /^1[3456789][0-9]{9}$/.test(a)
    })

    onMounted(() => {
      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.KefuTelService.getSlogn').then(res => {
        slogan.value = res.result
      })
    })

    return {
      onSubmit,
      tel,
      code,
      password,
      checked,
      clickable,
      slogan,
      handleSendCode
    }
  },
  components: {
    countDown,
  }
}
</script>
<template>
  <div class="page">
    <van-form @submit="onSubmit"  validate-trigger="onSubmit" >
      <h3>商户账号注册<p class="slogan">{{slogan}}</p></h3>
      <van-field
        v-model="tel"
        name="tel"
        maxlength="11"
        type="number"
        placeholder="请输入手机号码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{
          required: true,
          message: '请输入手机号码' 
        }, {
          pattern: /^1[3456789][0-9]{9}$/, 
          message: '请输入正确手机号格式'
        }]"
      />
      <van-field
        v-model="code"
        label-width="1rem"
        name="code"
        maxlength="4"
        type="number"
        placeholder="请输入验证码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{
          required: true,
          message: '请输入验证码' 
        }
        ]"
      >
        <template #extra>
          <countDown 
            :clickControlled="clickable" 
            @handleSend="handleSendCode"
          >
            <span class="vcode">发送验证码</span>
          </countDown>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="请输入密码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{ 
          required: true, 
          message: '请输入密码' 
        }, 
        // {
        //   pattern: /^[a-zA-Z0-9]{6,16}$/, 
        //   message: '请输入6-16位数字字母组合密码'
        // }
        ]"
      />
      <div style="margin: 30px 0 0;">
        <van-button block type="primary" native-type="submit">注册</van-button>
      </div>
      <div style="margin-top:20px; text-align:center">
        <p style=" margin-top: 20px;font-size:0.24rem; text-align: center; color:#666;">
          <van-checkbox v-model="checked" checked-color="#00abeb" icon-size="14px" shape="square">
            注册代表已阅读并同意<a @click="$router.push('/blend-view/agreement?agreementType=MERCHANT_USER_PROTOCOL')" style="color:#00ABEB;">《用户协议》</a>与<a @click="$router.push('/blend-view/agreement?agreementType=MERCHANT_PRIVACY_POLICY')"  style="color:#00ABEB;">《隐私政策》</a>
          </van-checkbox>
        </p>
      </div>
    </van-form>
  
  </div>
</template>
<style scoped>
  .page {height: 100%; overflow: auto;padding: 0 .6rem; box-sizing: border-box}
  .concat { line-height: 1.5; font-size: .24rem; text-align:center }
  .concat p {  padding-top:80%}
  h3 { margin:1rem 0; font-size:.46rem; }
  .van-cell::after { border:none }
  .vcode { color:#00abeb; font-size:.26rem }
  .slogan { margin-top: .3rem; font-size:.28rem; font-weight:normal; color:#999 }
</style>